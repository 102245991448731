import { SortDirection } from '@app/modules/@core/core.types';
import { OrdersListFilterSettings } from './orders.state';

export const ORDERS_STATE_TYPE_NAME = 'ordersState';

export class LoadOrdersAction {
  static type = `[${ORDERS_STATE_TYPE_NAME}] LoadOrders`;
  constructor(
    public options: {
      pageSize?: number;
      pageToLoad?: number;
      orderBy?: string;
      orderDirection?: SortDirection;
      filter?: OrdersListFilterSettings;
    } = {}
  ) {}
}
export class ConfirmOrderAction {
  static type = `[${ORDERS_STATE_TYPE_NAME}] ConfirmOrderAction`;
  constructor(public orderId: string) {}
}
