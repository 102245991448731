import { Injectable } from '@angular/core';
import { NIFTY_APPLICATIONID_KEY } from '@app/app.constants';
import {
  Order,
  Pagination,
  PaginationAndOrdering,
  SortDirection,
  StatePageSettings,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { OrdersService } from '@core/services/orders.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  ConfirmOrderAction,
  LoadOrdersAction,
  ORDERS_STATE_TYPE_NAME,
} from './order.actions';

export const ORDERS_LIST_PAGE_SIZE = 20;

export interface OrdersListFilterSettings {
  orderIdToken?: string;
  partnerId?: string;
  applicationId?: string;
  orderTypeIds?: string;
  status?: string;
  partnerIds?: string;
  paymentTypes?: string;
  bundleIds?: string;
  couponIds?: string;
  couponToken?: string;
  notes?: string;
  lastStatuses?: string;
  statusChangedFrom?: string;
  statusChangedTo?: string;
  lastStatusChangedFrom?: string;
  lastStatusChangedTo?: string;
  lastStatus?: string;
  includeOrderItems?: boolean;
  invoiceIdToken?: string;
}

export interface OrdersStateModel {
  orders: { [id: string]: Order };
  pages?: {
    items: string[];
    continuationToken: string;
  }[];
  currentPageSettings: StatePageSettings<OrdersListFilterSettings>;
  totalCount?: number;
}

const DEFAULT_ORDERS_STATE: OrdersStateModel = {
  orders: {},
  pages: [],
  currentPageSettings: {
    pageIndex: 0,
    pageSize: ORDERS_LIST_PAGE_SIZE,
    sortSettings: {
      orderBy: 'Placed',
      sortDirection: SortDirection.desc,
    },
    filterSettings: {
      applicationId: NIFTY_APPLICATIONID_KEY,
      includeOrderItems: true,
      statusChangedFrom: null,
      statusChangedTo: null,
      status: null,
      partnerIds: null,
    },
  },
  totalCount: 0,
};

@State<OrdersStateModel>({
  name: ORDERS_STATE_TYPE_NAME,
  defaults: {
    ...DEFAULT_ORDERS_STATE,
  },
})
@Injectable()
export class OrdersState {
  private readonly log = new Logger(this.constructor.name);

  constructor(private ordersService: OrdersService) {}

  @Selector()
  static getCurrentPage(state: OrdersStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;
    if (pages.length === 0 || pageIndex > pages.length || pageIndex < 0) {
      return [];
    }
    return state.pages[pageIndex].items.slice();
  }

  @Selector()
  static getOrderById({ orders }: OrdersStateModel) {
    return (orderId: string) => orders[orderId];
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: OrdersStateModel): Pagination<OrdersListFilterSettings> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: pages.length,
      collectionSize: currentPageSettings.pageSize,
    };
  }

  @Action(LoadOrdersAction)
  loadOrders(
    { getState, patchState, dispatch }: StateContext<OrdersStateModel>,
    { options }: LoadOrdersAction
  ) {
    this.log.debug('LoadOrdersAction');
    const currentState = getState();
    let isReloadRequired = false;
    let { pageSize } = currentState.currentPageSettings;
    let { sortDirection, orderBy } =
      currentState.currentPageSettings.sortSettings;
    if (options.orderBy && options.orderBy !== orderBy) {
      orderBy = options.orderBy;
      isReloadRequired = true;
    }
    if (options.orderDirection && options.orderBy !== sortDirection) {
      sortDirection = options.orderDirection;
      isReloadRequired = true;
    }
    if (options.pageSize && options.pageSize !== pageSize) {
      pageSize = options.pageSize;
      isReloadRequired = true;
    }
    let filterSettings = currentState.currentPageSettings.filterSettings;
    if (this.ordersListFilterChanged(filterSettings, options.filter)) {
      isReloadRequired = true;
      filterSettings = options.filter;
    }
    let pageToLoad = options.pageToLoad > 0 ? options.pageToLoad - 1 : 0;
    this.log.debug('load orders page', pageToLoad, isReloadRequired);
    const paginationAndOrdering: PaginationAndOrdering = {
      orderDirection: sortDirection,
      top: pageSize,
      orderBy,
    };
    if (isReloadRequired) {
      const { pages, currentPageSettings, totalCount } = DEFAULT_ORDERS_STATE;
      patchState({ pages, currentPageSettings, totalCount });
      pageToLoad = 0;
    } else if (currentState.pages.length > pageToLoad) {
      this.log.debug('return already loaded page', pageToLoad);
      return patchState({
        currentPageSettings: {
          sortSettings: {
            sortDirection,
            orderBy,
          },
          filterSettings,
          pageIndex: pageToLoad,
          pageSize,
        },
      });
    } else {
      pageToLoad = currentState.pages.length || 0;
      if (pageToLoad > 0) {
        paginationAndOrdering.continuationToken =
          currentState.pages[pageToLoad - 1].continuationToken;
      }
    }
    if (filterSettings) {
      paginationAndOrdering.filters =
        this.getFiltersFromFilterSettings(filterSettings);
    }
    return this.ordersService.getFilteredEntities(paginationAndOrdering).pipe(
      tap((response) => {
        if (response) {
          const { entitiesList, newContinuationToken, totalCount } = response;
          if (entitiesList) {
            const orders = { ...getState().orders };
            for (const order of entitiesList) {
              orders[order.id] = order;
            }
            return patchState({
              currentPageSettings: {
                sortSettings: {
                  sortDirection,
                  orderBy,
                },
                filterSettings,
                pageIndex: pageToLoad,
                pageSize,
              },
              totalCount,
              pages: [
                ...getState().pages,
                {
                  items: entitiesList.map((t) => t.id),
                  continuationToken: newContinuationToken,
                },
              ],
              orders,
            });
          }
        }
      })
    );
  }

  @Action(ConfirmOrderAction)
  confirmOrder(
    { getState, patchState, dispatch }: StateContext<OrdersStateModel>,
    { orderId }: ConfirmOrderAction
  ) {
    this.log.debug('confirmOrder');

    return this.ordersService.markOrderAsConfirmed(orderId).pipe(
      catchError((err) => throwError(err)),
      switchMap((result) => {
        if (result === null) {
          return this.ordersService.getOrderById(orderId).pipe(
            switchMap((order) => {
              const orders = getState().orders;

              const oldOrder = orders[orderId];

              const ordersListCopy = { ...orders };

              ordersListCopy[orderId] = order;
              return of(
                patchState({
                  orders: ordersListCopy,
                })
              );
            })
          );
        }

        return of(result);
      })
    );
  }

  private getFiltersFromFilterSettings(
    filterSettings: OrdersListFilterSettings
  ) {
    const filters = new Map<string, string>();
    for (const key of Object.keys(filterSettings)) {
      if (filterSettings[key]) {
        filters.set(key, filterSettings[key]);
      }
    }
    return filters;
  }

  private ordersListFilterChanged(
    a: OrdersListFilterSettings,
    b: OrdersListFilterSettings
  ): boolean {
    if (a && b) {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(a);
      if (
        aKeys.length === bKeys.length &&
        aKeys.findIndex((aKey) => a[aKey] !== b[aKey]) < 0
      ) {
        return false;
      }
    }
    return true;
  }
}
