import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { Order } from '../core.types';
import { EntityBaseService } from './entityBase.service';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends EntityBaseService<Order> {
  private readonly log = new Logger(this.constructor.name);
  private baseOrdersUrl: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.baseApiEndpoint = `${environment.apiUrl}/`;
    this.apiUrl = this.baseApiEndpoint;
    this.baseOrdersUrl = `${this.baseApiEndpoint}orders${environment.actorsPrefix}`;
  }

  getOrderById(orderId: string): Observable<any> {
    this.log.debug('get order by id');
    const getOrderUrl = `${this.baseOrdersUrl}/OrderProjector/${orderId}/ask/GetOrder`;

    return this.http.get(getOrderUrl);
  }

  markOrderAsConfirmed(orderId: string): Observable<any> {
    this.log.debug('mark order as confirmed');

    const marOrderAsConfirmedUrl = `${this.baseOrdersUrl}/Order/${orderId}/tell/ConfirmOrder`;
    return this.http.post(marOrderAsConfirmedUrl, null);
  }

  protected setUrlBody(order: Order): Partial<Order> {
    return order;
  }

  protected getListUrl(): string {
    return `${this.baseOrdersUrl}/OrderDirectory/!/ask/ListOrders`;
  }

  protected getUpdateEntityUrl(entityId: string): string {
    throw new Error('Not implemented');
  }
  protected getDeleteEntityUrl(entityId: string): string {
    throw new Error('Not implemented');
  }
  protected getCreateEntityUrl(newEntityId: string): string {
    throw new Error('Not implemented');
  }
  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Not implemented');
  }
  protected setCreateEntityBody(entity: Order, body: Partial<Order>) {
    throw new Error('Not implemented');
  }
  protected setUpdateEntityBody(entity: Order, body: Partial<Order>) {
    throw new Error('Not implemented');
  }
}
